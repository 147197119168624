import { CONSTANTS } from 'src/api/constants';
let initState = {
  data: [],
  parameterList: [],
  parameterData: [
    {
      param: []
    }
  ],
  pagination: {
    itemPerPage: 10,
    currentPage: 1,
    totalPage: 1
  },
  searchValue: '',
  dataSearch: [],
  error: null,
  displayView: false,
  selectedTank: null
}

export function parameter(state = initState, action) {
  let __state = {}
  switch (action.type) {
    case CONSTANTS.PARAMETER.DISPLAY_VIEW:
      state = {
        ...state,
        displayView: action.payload
      }
      break
    case CONSTANTS.PARAMETER.SET_SELECTED_TANKID:
      state = {
        ...state,
        selectedTank: action.payload
      }
      break
    case CONSTANTS.PARAMETER.LIST_SUCCESS:
      state = updateParameterList(state, action.result)
      break;
    case CONSTANTS.PARAMETER.LIST_FAILURE:
      if (typeof (state.data) === 'undefined') {
        state.message = action.err
        return state
      }
      return {
        message: action.message,
        data: [...state.data]
      };
    case CONSTANTS.PARAMETER.LOGS_COUNT_CHANGE:
      __state = { ...state }
      __state.pagination.itemPerPage = action.result
      __state.pagination.currentPage = 1
      __state.pagination.totalPage = Math.ceil(__state.parameterData.length / action.result)

      __state.parameterList = []
      for (let i = 0; i < __state.parameterData.length; i++) {
        if (i < action.result) {
          __state.parameterList.push(__state.parameterData[i])
        }
      }

      state = {
        ...__state
      };
      return state;
    case CONSTANTS.PARAMETER.LOGS_PAGE_CHANGE:
      __state = { ...state }
      __state.pagination.currentPage = action.result

      __state.parameterList = []
      let _j = 0
      for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.parameterData.length; i++) {

        if (_j < state.pagination.itemPerPage) {
          __state.parameterList.push(__state.parameterData[i])
          _j++

        }
      }

      state = {
        ...__state
      };
      return state;

    case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
  return state
}

const updateParameterList = (state, data) => {
  let { ..._state } = state


  let _data = []
  for (let i = 0; i < data.length; i++) {

    if (i < 10) {
      _data.push(data[i])
    }
  }

  _state = {
    ..._state,
    parameterData: data,
    parameterList: _data,
    pagination: {
      itemPerPage: 10,
      currentPage: 1,
      totalPage: Math.ceil(data.length / state.pagination.itemPerPage)
    },
    error: null
  };
  return _state;
}