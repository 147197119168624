var API_ROUTE = ''
var RTAPI_URL = ''

let dev = true
if (typeof process.env.REACT_APP_MODE === 'undefined') {
	if (typeof process.env.NODE_ENV !== 'undefined') {
		if (process.env.NODE_ENV === 'production') {
			dev = false
		}
	}
} else {
	if (process.env.REACT_APP_MODE !== 'dev') {
		dev = false
	}
}
if (dev) {
	API_ROUTE = 'http://' + window.location.hostname + ':3055/api'
	RTAPI_URL = 'ws://' + window.location.hostname + ':7111'
} else {
	API_ROUTE = 'https://' + window.location.hostname + '/api'
	RTAPI_URL = 'wss://' + window.location.hostname + '/ws'
}

export const SERVER = {
	API: {
		Login: API_ROUTE + '/AppUsers/login',
		Logout: API_ROUTE + '/AppUsers/logout',
		ChangePassword: API_ROUTE + '/AppUsers/change-password',
		ResetPassword: API_ROUTE + '/AppUsers/reset/password',

		AppUser: {
			MAIN: API_ROUTE + '/AppUsers',

			Register: API_ROUTE + '/AppUsers',
			Login: API_ROUTE + '/AppUsers/login',
			Logout: API_ROUTE + '/AppUsers/logout',

			ListSystemUser: API_ROUTE + '/AppUsers/list/systemuser',
			UpdateUser: API_ROUTE + '/AppUsers',
			UpdatePassword: API_ROUTE + '/AppUsers/update',

			RegisterManager: API_ROUTE + '/AppUsers/register/manager',
			RegisterInspector: API_ROUTE + '/AppUsers/register/inspector',

			UpdateManager: API_ROUTE + '/AppUsers/update/manager',
			UpdateInspector: API_ROUTE + '/AppUsers/update/inspector',

			DeleteManager: API_ROUTE + '/AppUsers/delete/manager',
			DeleteInspector: API_ROUTE + '/AppUsers/delete/inspector',

			List: API_ROUTE + '/AppUsers',
			DeleteOne: API_ROUTE + '/AppUsers/',
			EditOne: API_ROUTE + '/AppUsers/update?where=',
			createUser: API_ROUTE + '/api/AppUsers/create/user',

		},
		AppLocation: {
			List: API_ROUTE + '/Locations/details',
			EditOne: API_ROUTE + '/Locations/update?where=',
			createLocation: API_ROUTE + '/Locations/create/location',
			DeleteOne: API_ROUTE + '/Locations/',
		},
		AppTank: {
			List: API_ROUTE + '/Tanks',
			EditOne: API_ROUTE + '/Tanks/update?where=',
			createTank: API_ROUTE + '/Tanks/create/tank',
			DeleteOne: API_ROUTE + '/Tanks/',
		},
		AppSensor: {
			List: API_ROUTE + '/Sensors',
			EditOne: API_ROUTE + '/Sensors/',
			createSensor: API_ROUTE + '/Sensors/',
			DeleteOne: API_ROUTE + '/Sensors/',
			Delete: API_ROUTE + '/Sensors/',
			DeleteParameter: API_ROUTE + '/Sensors/',
			createParameter: API_ROUTE + '/Sensors/',
			updateParameter: API_ROUTE + '/Sensors/',
			updateSensorByParameter: API_ROUTE + '/Parameters/'
		},
		AppParameter: {
			List: API_ROUTE + '/ParameterData',
			ParamDetail: API_ROUTE + '/Parameters'
		},
		AppParameterData: {
			Latest: API_ROUTE + '/ParameterData/latest',
			List: API_ROUTE + '/ParameterData/history',
			Filtered: API_ROUTE + '/ParameterData/filtered',
			EditOne: API_ROUTE + '/ParameterData/'
		},
	},
	RTAPI: {
		URL: RTAPI_URL,
		User: 'wms_dash',
		Pass: 'asdh@453bia&2sfeeYq3rs'
	}
}