import { combineReducers } from 'redux';
// import { authorization } from './r_authorization'
import { dashboard } from './r_dashboard'
import { users } from './r_users'
import { sensordata } from './r_sensordata'
import { sensor } from './r_sensor'
import { updateview } from './updateview'
import { location } from './r_location'
import { tank } from './r_tank'
import { parameter } from './r_parameter'
import { parameterdata } from './r_parameterData'

const rootReducer = combineReducers({
  updateview,
  // authorization,
  dashboard,
  users,
  sensordata,
  sensor,
  location,
  tank,
  parameter,
  parameterdata
});

export default rootReducer;