import { Mqtt, WebSocketClient } from '../__ifunc'
// import alertify from 'alertifyjs'
import store from 'src/store'
import { TankAction } from 'src/__actions/tank'
import { CONSTANTS } from './constants';

const moment = require('moment');

const MQTT = 1
const WEBSOCKET = 2

// Select Mode
const mode = MQTT
// const mode = WEBSOCKET
// const mode = SOCKETIO

// let __dispatch = null

class RTApi {
    start() {
        // __dispatch = dispatch

        if (mode === MQTT) {
            this.instance = new Mqtt()
            this.instance.start(receiveMessage)
        } else if (mode === WEBSOCKET) {
            this.instance = new WebSocketClient(receiveMessage)
            this.instance.open()
        }
    }
    stop() {
        if (this.instance !== null) {
            this.instance.close()
        }
    }
}
export default RTApi

function receiveMessage(topic, message) {
    // console.log(topic, message)
    if (topic === 'parameterData') {
        const state = store.getState()
        // console.log(state)

        if (typeof state.parameter === 'undefined') {
            return
        }
        if (state.parameter.displayView) {
            if (message.tankId !== state.parameter.selectedTank) {
                return
            }
            store.dispatch({ type: 'LIVE_DATA', data: message })

            let date = moment(new Date()).format("YYYY-MM-DD")
            let filter = { "where": { "id": state.parameter.selectedTank }, "include": { "relation": "sensors", "scope": { "include": { "relation": "parameters", "scope": { "include": { "relation": "param", "scope": { "where": { "updatedAt": { "gt": date } } } } } } } } }

            // let filter = { "include": { "relation": "sensors", "scope": { "include": { "relation": "parameters", "scope": { "include": { "relation": "param", "scope": { "where": { "updatedAt": { "gt": date } } } } } } } } }
            TankAction.getTankList(filter).then(result => {
                store.dispatch({ type: CONSTANTS.TANK.DETAIL_LIST_SUCCESS, result });
            })
        }

    } else if (topic === 'lowAlert') {
        store.dispatch({ type: 'LIVE_LOW_ALERT_DATA', data: message })
    } else if (topic === 'highAlert') {
        store.dispatch({ type: 'LIVE_HIGH_ALERT_DATA', data: message })
    }
}