import { connect } from 'mqtt';
import {
    // CONSTANTS,
    SERVER
} from '../api'

var client = null;

class Mqtt {
    start(cb) {
        if (client !== null) {
            return
        }

        client = connect(SERVER.RTAPI.URL, {
            username: SERVER.RTAPI.User,
            password: SERVER.RTAPI.Pass,
            clientId: 'web_client_' + Math.random().toString(16).substr(2, 8),
        });

        client.on('connect', function () {
            console.log("Connected to broker");
            client.subscribe('#');
        })
        client.on('message', function (topic, message) {
            // message is Buffer 
            let _data = JSON.parse(message.toString())
            // console.log(topic, _data)
            cb(topic, _data)

        });
        client.on('error', function (err) {
            // console.log('MQTT error: '+err);
        });
        client.on('close', function (err) {
            // console.log('MQTT close: '+err);
        });
    }
    close() {
        if (client !== null) {
            client.end()
            client = null
        }
    }
}
export default Mqtt;