import { CONSTANTS, NFetch, SERVER } from '../api';

export const TankAction = {
  tank__destroy: tank__destroy,
  tank__list: tank__list,
  tank__update: tank__update,
  tank__create: tank__create,
  get_tankInfo: get_tankInfo,
  getTankList
}

async function tank__destroy(tankId, dispatch) {
  try {
    let result = await NFetch.Delete(SERVER.API.AppTank.DeleteOne + tankId)
    dispatch({ type: CONSTANTS.TANK.DELETE_SUCCESS, result });
    return result
  } catch (error) {
    return Promise.reject(error)
  }
}
async function tank__list(locationId, dispatch) {
  try {
    let filter = { where: { locationId: locationId } }
    let result = await NFetch.GetWithFilter(SERVER.API.AppTank.List, filter)
    dispatch({ type: CONSTANTS.TANK.LIST_SUCCESS, result })
    return result
  } catch (error) {
    return Promise.reject(error)
  }
}
async function tank__update(filter, attr, dispatch) {
  try {
    let result = await NFetch.Post(SERVER.API.AppTank.EditOne + filter, attr)
    dispatch({ type: CONSTANTS.TANK.UPDATE_SUCCESS, result })
    return result
  } catch (error) {
    return Promise.reject(error)
  }
}
async function tank__create(param, dispatch) {
  try {
    let result = await NFetch.Post(SERVER.API.AppTank.createTank, param)
    dispatch({ type: CONSTANTS.TANK.REGISTER_SUCCESS, result })
    return result
  } catch (error) {
    return Promise.reject(error)
  }
}
async function get_tankInfo(tankId, dispatch) {
  try {
    let filter = { "where": { "id": tankId }, "include": { "relation": "sensors", "scope": { "include": { "relation": "parameters", "scope": { "include": { "relation": "param", "scope": { "where": { "updatedAt": { "gt": new Date() } } } } } } } } }
    let result = await NFetch.GetWithFilter(SERVER.API.AppTank.List, filter)
    dispatch({ type: CONSTANTS.TANK.DETAIL_LIST_SUCCESS, result })
    dispatch({ type: CONSTANTS.TANK.DETAIL_PARAMLIST_SUCCESS, result })
    return result
  } catch (error) {
    return Promise.reject(error)
  }
}

function getTankList(filter){
  return NFetch.GetWithFilter(SERVER.API.AppTank.List, filter)
}
