import { APPS_AUTH_USER, STORAGE } from './config'

export const getAccessToken = () => {
    let token = (STORAGE === 'session') ? JSON.parse(sessionStorage.getItem(APPS_AUTH_USER)) : JSON.parse(localStorage.getItem(APPS_AUTH_USER));
    if (token && token.token) {
        return token.token
    } else {
        return null
    }
}
