import { CONSTANTS } from 'src/api/constants';
let initState = {
    data: [],
    tankDetail: [],
    tankData: [],
    tank: [],
    pagination: {
        itemPerPage: 10,
        currentPage: 1,
        totalPage: 1
    },
    searchValue: '',
    dataSearch: [],
    tanksInfo: [
        {
            name: '',
            sensors: [
                {
                    parameters: [
                        {
                            param: []
                        }
                    ]
                }
            ]
        }
    ],
    paramByTank: [],
}

export function tank(state = initState, action) {
    let __state = {}
    let _data = action.result

    switch (action.type) {
        case CONSTANTS.TANK.LIST_SUCCESS:
            state = updateTankList(state, action.result)
            break;


        case CONSTANTS.TANK.LIST_FAILURE:
            if (typeof (state.data) === 'undefined') {
                state.message = action.err
                return state
            }
            return {
                message: action.message,
                data: [...state.data]
            };

        case CONSTANTS.TANK.LOGS_COUNT_CHANGE:
            __state = { ...state }
            __state.pagination.itemPerPage = action.result
            __state.pagination.currentPage = 1
            __state.pagination.totalPage = Math.ceil(__state.tankData.length / action.result)

            __state.tankDetail = []
            for (let i = 0; i < __state.tankData.length; i++) {
                if (i < action.result) {
                    __state.tankDetail.push(__state.tankData[i])
                }
            }

            state = {
                ...__state
            };
            return state;
        case CONSTANTS.TANK.LOGS_PAGE_CHANGE:
            __state = { ...state }
            __state.pagination.currentPage = action.result

            __state.tankDetail = []
            let _j = 0
            for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.tankData.length; i++) {

                if (_j < state.pagination.itemPerPage) {
                    __state.tankDetail.push(__state.tankData[i])
                    _j++

                }
            }

            state = {
                ...__state
            };
            return state;

        case CONSTANTS.TANK.DETAIL_LIST_SUCCESS:
            return {
                ...state,
                tanksInfo: _data
            }
        case CONSTANTS.TANK.DETAIL_PARAMLIST_SUCCESS:
            // console.log("checkkkkkkk-->", _data)
            let pArray = [];
            // let paramlist = [];

            let __ListParameter = []
            let __listSensor = _data.map((items) => {
                if (items.sensors.length > 0) {
                    let __Parms = items.sensors.map((sensors) => {
                        return sensors.parameters
                    })
                    return __Parms
                }
                return []
            })
            // console.log(__listSensor)
            __listSensor.forEach(element => {
                __ListParameter = __ListParameter.concat(element)
            });
            // console.log(__ListParameter)
            __ListParameter.forEach(element => {
                pArray = pArray.concat(element)
            });

            // if (_data.length > 0) {
            //     _data.map((d) => {
            //         if (d.sensors.length > 0) {
            //             d.sensors.map((t) => {
            //                 t.parameters.map((p) => {

            //                     if (paramlist.length === 0) {
            //                         pArray.push(p)
            //                     } else {
            //                         paramlist.map(y => {
            //                             if (y.type !== p.type) {
            //                                 pArray.push(p)
            //                             }
            //                         })
            //                     }

            //                     // return null
            //                 })
            //             })

            //         }
            //     })
            // }

            // console.log(pArray)
            return {
                ...state,
                paramByTank: pArray
            }
        case CONSTANTS.CLEAR:
            return initState
        default:
            return state
    }
    return state
}

const updateTankList = (state, data) => {
    let { ..._state } = state


    let _data = []
    for (let i = 0; i < data.length; i++) {

        if (i < 10) {
            _data.push(data[i])
        }
    }

    _state = {
        ..._state,
        tankData: data,
        tankDetail: _data,
        pagination: {
            itemPerPage: 10,
            currentPage: 1,
            totalPage: Math.ceil(data.length / state.pagination.itemPerPage)
        },
        error: null
    };
    return _state;
}